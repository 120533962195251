<section class="margin-top container">
  <div class="wizard-sample-1">
    <div class="br-wizard">
      <div class="wizard-progress">
        <button 
          class="wizard-progress-btn" 
          type="button" 
          title="Dados Pessoais"
          [attr.active]="active1 == true ? 'active' : null" 
          [attr.disabled]="active1 == false ? 'disabled' : null" 
          step="1">
          <span class="info">Dados do Outorgante</span>
        </button>
        <button 
          class="wizard-progress-btn" 
          type="button" 
          title="Validar Dados"
          [attr.active]="active2 == true ? 'active' : null"
          [attr.disabled]="active2 == false ? 'disabled' : null"
          step='2'>
          <span class="info">Dados do Outorgado</span>
        </button>
        <button 
          class="wizard-progress-btn" 
          type="button" 
          title="Habilitar Cadastro" 
          [attr.active]="active3 == true ? 'active' : null"
          [attr.disabled]="active3 == false ? 'disabled' : null"
          step='3'>
          <span class="info">Abrangência</span>
        </button>
        <button 
          class="wizard-progress-btn" 
          type="button" 
          title="Cadastrar Senha" 
          [attr.active]="active4 == true ? 'active' : null"
          [attr.disabled]="active4 == false ? 'disabled' : null"
          step='4'>
          <span class="info">Revisão</span>
        </button>
        <button 
          class="wizard-progress-btn" 
          type="button" 
          title="Finalizar Cadastro"
          [attr.active]="active5 == true ? 'active' : null"
          [attr.disabled]="active5 == false ? 'disabled' : null"
          step='5'>
          <span class="info">Assinar</span>
        </button>
      </div>
    </div>
  </div>
  <div class="ml-5" *ngIf="active1">
    <h3>Dados do Outorgante</h3>
    <div class="row">
      <div class="col-4" *ngIf="cpfCnpjOutorgante.length == 14">
        <div class="br-input">
          <label>Representante Legal</label>
          <input type="text" class="input" disabled [(ngModel)]="representanteLegal"/>
        </div>
      </div>
      <div class="col-4" *ngIf="cpfCnpjOutorgante.length == 14">
        <div class="br-input">
          <label>CPF do Representante Legal</label>
          <input type="text" class="input" disabled [(ngModel)]="cpfRepresentanteLegal" mask="CPF_CNPJ"/>
        </div>
      </div>
      <div class="col-4">
        <div class="br-input">
          <label>Outorgante</label>
          <input type="text" class="input" disabled [(ngModel)]="outorgante" />
        </div>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-4">
        <div class="br-input">
          <label>CPF/CNPJ do Outorgante</label>
          <input type="text" class="input" disabled [(ngModel)]="cpfCnpjOutorgante" mask="CPF_CNPJ" />
        </div>
      </div>
      <div class="col-4">
        <div class="br-input">
          <label>Inscrição Estadual do Outorgante</label>
          <input type="text" class="input" disabled [(ngModel)]="ieOutorgante" />
        </div>
      </div>
    </div>
    <div class="div-botao mt-4">
      <button class="br-button primary posicao-botao" type="button" (click)="proximoPasso()">Próxima Etapa</button>
      <button class="br-button primary posicao-botao mr-4 desistir" type="button" (click)="desistir()">Desistir</button>
    </div>
  </div>
  <div class="ml-5" *ngIf="active2">
    <h3>Dados do Outorgado</h3>
    <form [formGroup]="outorgadoForm">
      <div class="row">
        <div class="col-4">
          <p class="label mb-3">Tipo de Pessoa<span class="obrigatorio label">*</span></p>
          <div class="br-radio">
            <input id="fisica" type="radio" name="tipoPessoa" value="0" formControlName="tipoPessoa" (click)="onTipoPessoaClick('fisica')">
            <label class="mr-5" for="fisica">Física</label>
            <input id="juridica" type="radio" name="tipoPessoa" value="1" formControlName="tipoPessoa" (click)="onTipoPessoaClick('juridica')">
            <label for="juridica">Jurídica</label>
          </div>
        </div>
        <div class="col-4">
          <div class="br-input">
            <label>CPF/CNPJ<span class="obrigatorio label">*</span></label>
            <input 
              type="text" 
              mask="CPF_CNPJ"
              formControlName="cpfCnpj"
              [readonly]="readonly == true"
              [maxlength]="outorgadoForm.controls.tipoPessoa.value == 0 ? 14 : 18" 
              (blur)="validarCpfCnpj(); verificarAdesaoUsuario(); validarDigitoCpfCnpj()" />
            <p 
              class="mensagem-erro" 
              *ngIf="outorgadoForm.controls.cpfCnpj.invalid && outorgadoForm.controls.cpfCnpj.touched"
              >
              CPF/CNPJ inválido
            </p>
          </div>
        </div>
        <div class="col-4">
          <div class="br-input">
            <label>Nome do Procurador<span class="obrigatorio label">*</span></label>
            <input type="text"formControlName="nomeProcurador" maxlength="200" minlength="5" [readonly]="readonly" />
            <p 
              class="mensagem-erro" 
              *ngIf="outorgadoForm.controls.nomeProcurador.invalid && outorgadoForm.controls.nomeProcurador.touched"
              >
              Mínimo 5 carácteres
            </p>
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-4">
          <div class="br-input">
            <label>E-mail<span class="obrigatorio label">*</span></label>
            <input type="email" formControlName="email" email [readonly]="readonly" maxlength="100"/>
            <p 
              class="mensagem-erro" 
              *ngIf="outorgadoForm.controls.email.invalid && outorgadoForm.controls.email.touched"
              >
              E-mail inválido
            </p>
          </div>
        </div>
        <div class="col-4">
          <div class="br-input">
            <label>Tipo de Documento<span class="obrigatorio label">*</span></label>
            <input type="text" class="input-vazio">
            <select class="select" formControlName="tipoDocumento" (change)="tipoDocumentoSelecionado($event)">
              <option disabled selected>Selecione a Relação</option>
              <option *ngFor="let item of ged" [value]="item.codigo">{{ item.descricaoGed }}</option>
            </select>
          </div>
        </div>
        <div class="col-4">
          <div class="br-input">
            <label>Documento<span class="obrigatorio label">*</span></label>
            <input 
              type="text"
              formControlName="documento"
              [mask]="mascaraAtual"
              [dropSpecialCharacters]="false"
              [attr.maxlength]="rgMaxlength"
            />
          </div>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-4">
          <div class="br-input">
            <label>Nome da Mãe</label>
            <input 
              type="text" 
              formControlName="nomeMae"
              maxlength="150"
              mask="A*" [patterns]="customPatterns">
          </div>
        </div>
        <div class="col-4">
          <div class="br-upload">
            <label class="upload-label">Cópia de Documento</label>
            <input class="upload-input" type="file" accept="application/pdf" #file_input (change)="anexo($event)" />
            <button class="upload-button" type="button" (click)="file_input.click()">
              <fa-icon [icon]="['fas', 'upload']">
              </fa-icon>
              <span>Selecione o arquivo</span>
            </button>
            <div class="upload-list mt-2 file-name" *ngFor="let item of arquivos; let i = index">{{ item.name }} 
              <fa-icon class="lixeira" [icon]="['fas', 'trash']" (click)="deletarArquivo(i)"></fa-icon></div>
          </div>
        </div>
      </div>
    </form>
    <div class="div-botao mt-4">
      <button class="br-button primary posicao-botao" type="button" [disabled]="!outorgadoForm.valid" (click)="proximoPasso()">Próxima Etapa</button>
      <button class="br-button secondary posicao-botao mr-4" type="button" (click)="voltarPasso()">Etapa Anterior</button>
      <button class="br-button primary posicao-botao mr-4 desistir" type="button" (click)="desistir()">Desistir</button>
    </div>
  </div>
  <div class="ml-5" *ngIf="active3">
    <h3>Abrangência</h3>
    <form [formGroup]="abrangenciaForm">
      <div class="row">
        <div class="col-4">
          <div class="br-input">
            <label>Tipo de Procuração<span class="obrigatorio label">*</span></label>
            <input type="text" class="input-vazio">
            <select class="select" formControlName="tipoProcuracao" (change)="getTipoProcuracaoIndex()">
              <option disabled selected>Selecione a Relação</option>
              <option *ngFor="let item of tipoProcuracao" [ngValue]="item.codTipoProcuracao">{{ item.dscTipoProcuracao }}</option>
            </select>
          </div>
        </div>
        <div class="col-4">
          <div class="br-input">
            <label>Data Início de Outorga<span class="obrigatorio label">*</span></label>
            <input type="text" formControlName="dataInicio" [(ngModel)]="dataInicio" readonly>
          </div>
          <!-- <dp-day-calendar
            theme="dp-material"
            formControlName="dataInicio"
            [(ngModel)]="dataInicio"
            (ngModelChange)="getDataInicio($event)"
            #dayPicker
          ></dp-day-calendar> -->
        </div>
        <div class="col-4">
          <div class="br-input">
            <label>Data Fim de Outorga<span class="obrigatorio label">*</span></label>
          </div>
          <dp-date-picker 
              theme="dp-material"
              [config]="datePickerConfig"
              formControlName="dataFim"
              [(ngModel)]="dataFim"
              (ngModelChange)="getDataFim($event)"
            >
          </dp-date-picker>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-6">
          <label>Poderes da procuração: <span class="obrigatorio label">*</span></label>
          <div *ngFor="let item of listaPoderes">
            <div class="br-checkbox mt-3">
              <input [id]="item.dscMenu" [checked]="item.selected" type="checkbox" (click)="checkboxToggle(item)"/>
              <label [for]="item.dscMenu">{{ item.dscMenu }}</label>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="div-botao mt-4">
      <button class="br-button primary posicao-botao" type="button" [disabled]="!abrangenciaForm.valid || poderesSelecionados.length == 0" (click)="proximoPasso()">Próxima Etapa</button>
      <button class="br-button secondary posicao-botao mr-4" type="button" (click)="voltarPasso()">Etapa Anterior</button>
      <button class="br-button primary posicao-botao mr-4 desistir" type="button" (click)="desistir()">Desistir</button>
    </div>
  </div>
  <div class="ml-5" *ngIf="active4">
    <h3>Revisão</h3>
    <div>
      <h4>Dados do Outorgante</h4>
      <div class="container">
        <div class="row" *ngIf="cpfCnpjOutorgante.length == 14">
          <div class="col-6 d-flex">
            <label class="titulo">Representante Legal: </label>
            <p class="texto pt-1 ml-2">{{ dadosFormatados.sessao.cadastro.dscNomeCadastro }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">CPF do Representante Legal: </label>
            <p class="texto pt-1 ml-2">{{ dadosFormatados.sessao.cadastro.cpfCnpj | mask: 'CPF_CNPJ' }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Outorgante: </label>
            <p class="texto pt-1 ml-2">{{ contribuinteFormatado.dscRazaoSocial }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">CPF/CNPJ do Outorgante: </label>
            <p class="texto pt-1 ml-2">{{ contribuinteFormatado.cadastro.cpfCnpj | mask: 'CPF_CNPJ'}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Inscrição Estadual do Outorgante: </label>
            <p class="texto pt-1 ml-2">{{ contribuinteFormatado.dscIe }}</p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h4>Dados do Outorgado</h4>
      <div class="container">
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Tipo de Pessoa: </label>
            <p class="texto pt-1 ml-2">{{ outorgadoForm.controls.tipoPessoa.value == 0 ? 'Física' : 'Jurídica' }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">CPF/CNPJ do Procurador: </label>
            <p class="texto pt-1 ml-2">{{ outorgadoForm.controls.cpfCnpj.value | mask: 'CPF_CNPJ' }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Nome do Procurador: </label>
            <p class="texto pt-1 ml-2">{{ outorgadoForm.controls.nomeProcurador.value }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">E-mail: </label>
            <p class="texto pt-1 ml-2">{{ outorgadoForm.controls.email.value }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Tipo de Documento: </label>
            <p class="texto pt-1 ml-2">{{ getDescricaoDocumento(outorgadoForm.controls.tipoDocumento.value) }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Documento: </label>
            <p class="texto pt-1 ml-2">{{ outorgadoForm.controls.documento.value}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Nome da Mãe: </label>
            <p class="texto pt-1 ml-2">{{ outorgadoForm.controls.nomeMae.value }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Cópia de Documento: </label>
            <p class="texto pt-1 ml-2 file-name col-6" *ngFor="let arquivo of arquivos">{{ arquivo.name }}</p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <h4>Abrangência</h4>
      <div class="container">
        <div class="row">
          <div class="col-6 d-flex">
            <label class="titulo">Data Início da Outorga: </label>
            <p class="texto pt-1 ml-2">{{ dataInicio }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Data Fim da Outorga: </label>
            <p class="texto pt-1 ml-2">{{ dataFim | date: 'dd/MM/yyyy' }}</p>
          </div>
          <div class="col-6 d-flex">
            <label class="titulo">Tipo de Procuração: </label>
            <p class="texto pt-1 ml-2">{{ tipoProcuracao[indexTipoProcuracao].dscTipoProcuracao }}</p>
          </div>
        </div>
        <div class="row">
          <label class="col-12 d-flex titulo">Poderes da Procuração: </label>
          <div class="col-12 d-flex">
            <ul>
              <li class="texto" *ngFor="let item of poderesSelecionados">{{ item.dscMenu }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="div-botao mt-4">
      <button class="br-button primary posicao-botao" type="button" (click)="proximoPasso()">Confirmar Outorga</button>
      <button class="br-button secondary posicao-botao mr-4" type="button" (click)="voltarPasso()">Etapa Anterior</button>
      <button class="br-button primary posicao-botao mr-4 desistir" type="button" (click)="desistir()">Desistir</button>
    </div>
  </div>
  <div class="ml-5" *ngIf="active5">
    <h3>Assinatura</h3>
    <p>Atenção: Será necessário utilizar o site oficial do Governo Digital: 
      <a href="https://www.gov.br/governodigital/pt-br/assinatura-eletronica" target="_blank">Assinatura Eletrônica - Governo Digital (www.gov.br)</a>, que exigirá baixar cópia
      do termo de procuração, assinar no site e fazer a carga novamente, sem fechar a janela atual para evitar perda de dados.  
    </p>
    <div class="pdf-container">
      <pdf-viewer
        [src]="src"
        [rotation]="0"
        [original-size]="false"
        [show-all]="true"
        [fit-to-page]="false"
        [zoom]="0.5"
        [zoom-scale]="'page-width'"
        [stick-to-page]="false"
        [render-text]="true"
        [external-link-target]="'blank'"
        [autoresize]="true"
        [show-borders]="false"
        style="width: 100%; height: 600px;"
      >
      </pdf-viewer>
    </div>
    <div class="div-botao-assinar mt-4">
      <button class="br-button primary botao-central" (click)="toggleModal()">Assinar pelo Governo Digital</button>
    </div>
    <div class="backdrop" *ngIf="downloadArquivo">
      <div class="div br-modal large position-modal">
        <div class="br-modal-header">Assinatura Digital</div>
        <div class="br-modal-body">
          Será efetuado uma cópia do documento e armazenado em seu computador. Localize o documento, 
          abra outra janela do seu navagador e acesso o sítio 
          <a href="https://www.gov.br/governodigital/pt-br/assinatura-eletronica" target="_blank">https://www.gov.br/governodigital/pt-br/assinatura-eletronica</a>
          e siga as instruções. <strong>Não feche esta janela</strong>
        </div>
        <div class="br-modal-footer justify-content-center">
          <button class="br-button secondary mr-4" type="button" (click)="toggleModal()">Voltar</button>
          <button class="br-button primary" type="button" (click)="baixarArquivo()">OK</button>
        </div>
      </div>
    </div>
    <div class="backdrop" *ngIf="envioAssinatura">
      <div class="div br-modal large position-modal">
        <div class="br-modal-header">Suba o arquivo assinado para formalizar a outorga</div>
        <div class="br-modal-footer justify-content-center">
          <div class="container">
            <div class="row justify-content-center">
              <div class="br-upload">
                <input class="upload-input" type="file" multiple="multiple" #file (change)="arquivoAssinado($event)" />
                <button class="upload-button" type="button" (click)="file.click()">
                  <fa-icon [icon]="['fas', 'upload']">
                  </fa-icon>
                  <span>Selecione o arquivo</span>
                </button>
                <div class="upload-list mt-3 mb-3">{{ arquivoAssinatura?.name }} </div>
              </div>
              <!-- <input class="hide" type="file" #file (change)="arquivoAssinado($event)" />
              <button class="br-button primary" type="button" (click)="file.click()">Carregar arquivo do computador</button> -->
            </div>
            <div class="row justify-content-center">
              <button class="br-button primary mr-4 desistir" type="button" (click)="desistirOutorga()">Desistir</button>
              <button class="br-button primary" type="button" (click)="confirmarOutorga()" [disabled]="!arquivoAssinatura">Confirmar Outorga</button>
            </div>
            <br>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="div-botao mt-4">
    <button class="br-button primary posicao-botao" type="button" (click)="proximoPasso()">Prosseguir</button>
    <button class="br-button secondary posicao-botao mr-4" type="button" (click)="desistir()" *ngIf="passo == 1">Desistir</button>
    <button class="br-button secondary posicao-botao mr-4" type="button" (click)="voltarPasso()" *ngIf="passo !== 1">Voltar</button>
  </div> -->
</section>

<div class="carregamento" *ngIf="loading">
  <img src="../../../assets/images/icones/logo-animado.svg">
</div>
