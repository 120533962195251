import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { timeout } from 'rxjs/operators';

import { ContribuinteService } from 'src/app/services/contribuinte.service';
import { IntegracaoService } from 'src/app/services/integracao.service';
import { TokenService } from 'src/app/services/token.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-integracao',
  templateUrl: './integracao.component.html',
  styleUrls: ['./integracao.component.scss']
})
export class IntegracaoComponent implements OnInit {

  dadosIntegracao: any;
  listaContribuintes: any;
  listaProcuradores: any;
  code: any;
  state: any;
  token: string;
  loading: boolean = false;

  constructor(
    private router: Router,
    private integracaoService: IntegracaoService,
    private contribuinteService: ContribuinteService,
    private tokenService: TokenService
    ) {

    }

    ngOnInit(): void {
      this.loading = true;
      this.integracao();
    }

    integracao() {
      let urlParametros = window.location.href.toString().split('/?code=')[1];
      if (urlParametros) {
        this.code = urlParametros.split('&state=')[0];
        this.state = urlParametros.split('&state=')[1];
        this.integracaoService.integracaoGovBr(this.code, this.state)
        .pipe(
            timeout(45000)
        )
        .subscribe(
          res => {
            this.dadosIntegracao = res;
            this.token = res['sessao'].token;
            localStorage.removeItem('dadosIntegracao');
            localStorage.removeItem('contribuinteEmUso');
            localStorage.setItem('dadosIntegracao', JSON.stringify(this.dadosIntegracao));
            this.tokenService.setToken(this.token);
            this.integracaoService.sendDadosIntegracao();
          },
          err => {
            Swal.fire({
                icon: 'error',
                text: 'Ops! Ocorreu um erro na integração. Por favor tente novamente.'
            });
            this.router.navigate(['pre-acesso']);
          },
          () => {
            if (
              this.dadosIntegracao.autorizado == true
              && !this.dadosIntegracao.sessao.contribuinte
            ) {
              this.contribuinteService.criarCadastroContribuinte(this.dadosIntegracao.sessao.cadastro.codigo).subscribe(
                res => {
                  localStorage.setItem('contribuinteEmUso', JSON.stringify(res));
                },
                err => {

                },
                () => {
                  this.tokenService.setToken(this.token);
                  this.router.navigate(['inicio']);
                  return
                }
              )
            } else if (
              this.dadosIntegracao.autorizado == false
            ) {
              localStorage.removeItem('dadosIntegracao');
              Swal.fire({
                icon: 'warning',
                html: this.dadosIntegracao.mensagem,
                allowOutsideClick: false
              }).then((result => {
                if (result.isConfirmed) {
                  this.router.navigate(['pre-acesso']);
                }
              }))
              return
            } else {
              this.tokenService.setToken(this.token);
              this.loading = false;
              this.router.navigate(['selecao-representante']);  
            }
          }
        )
      }
    }
}
