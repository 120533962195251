import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ResponseApi } from "../features/common/interface/responseApi";

@Injectable({
  providedIn: 'root'
})
export class ProcuracaoService {

  ApiUrl = environment.API_URL;

  constructor(private http: HttpClient) {

  }

  /**
    getProcuracoes(cpfCnpj) {
    return this.http.get(`${this.ApiUrl}/contribuinte/procurador/buscarProcuracoesPorContribuinte/${cpfCnpj}`);
  }
   */

  getGed() {
    return this.http.get(
      `${this.ApiUrl}/contribuinte/ged/listarTipoGedsIdentificador`
    )
  }

  getCpf(cpfCnpj) {
    return this.http.get(
      `${this.ApiUrl}/cadastros/cadastro/cpfCnpj/${cpfCnpj}`
    )
  }

  verificarAdesaoUsuario(cpfCnpj) {
    return this.http.get(`${this.ApiUrl}/cadastros/cadastro/adesao/cpfCnpj/${cpfCnpj}`);
  }

  enviarProcuracao(obj) {
    return this.http.post(
      `${this.ApiUrl}/contribuinte/procurador`, obj
    )
  }

  getTipoProcuracao() {
    return this.http.get(
      `${this.ApiUrl}/contribuinte/tipoProcuracao/listarRegistros`
    )
  }

  confirmarOutorga(obj, idProcuracao) {
    return this.http.put(
      `${this.ApiUrl}/contribuinte/procurador/atualizarProcuracaoAssinada/${idProcuracao}`, obj
    )
  }

  revogarProcuracao(idProcuracao, motivoRevoga, obj) {
    return this.http.put(
      `${this.ApiUrl}/contribuinte/procurador/revogarProcuracaoDocumento/${idProcuracao}/${motivoRevoga}`, obj, {
        responseType: 'text'
      } 
    )
  }

  getFiltro(contribuinteCodigo: any, searchParams: any): Observable<any>{
    const params = this.setParams(searchParams);
    return this.http.get<ResponseApi>(`${this.ApiUrl}/contribuinte/procurador/filtrarProcuracao/${contribuinteCodigo}`, {params})

  }

  setParams(searchParams) {
    let params = new HttpParams();
    if (!searchParams) {
      return params;
    }
    if (searchParams.key && searchParams.value) {
      return params.set(searchParams.key, searchParams.value);
    }
    for (const key of Object.keys(searchParams)) {
      params = params.set(key, searchParams[key]);
    }
    return params;
  }


}