import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class UsuarioLogadoService {

  dadosIntegracaoSubject = new BehaviorSubject<any>(null);
  dadosIntegracao: any;
  cadastroEmUso: any;
  contribuinteEmUsoSubject = new BehaviorSubject<any>(null);
  contribuinteEmUso: any;
  integracaoFormatado: any;
  cadastroFormatado: any;
  contribuinteFormatado;
  objIntegracao: any;
  objUsuario: any;

  constructor() {
    setTimeout(() => {
      this.getDados();
    }, 500);
  }
  
  getDados() {
    this.dadosIntegracao = localStorage.getItem('dadosIntegracao');
    this.cadastroEmUso = localStorage.getItem('cadastroEmUso');
    this.contribuinteEmUso = localStorage.getItem('contribuinteEmUso');
    this.integracaoFormatado = JSON.parse(this.dadosIntegracao);
    this.cadastroFormatado = JSON.parse(this.cadastroEmUso);
    this.contribuinteFormatado = JSON.parse(this.contribuinteEmUso);
    this.dadosIntegracaoSubject.next(this.integracaoFormatado);
    this.contribuinteEmUsoSubject.next(this.contribuinteFormatado);
  }

  getDadosIntegracao() {
    return this.integracaoFormatado;
  }
  
  getDadosIntegracaoSubject() {
    return this.dadosIntegracaoSubject.asObservable();
  }

  getContribuinteEmUsoSubject() {
    return this.contribuinteEmUsoSubject.asObservable();
  }

  getUsuarioLogado() {
    let obj: any = {};
    if (this.cadastroFormatado) {
      obj.dscRazaoSocial = this.cadastroFormatado.dscNomeCadastro;
      obj.cpfCnpj = this.cadastroFormatado.cpfCnpj;
      obj.codigoCadastro = this.cadastroFormatado.codigo;
      obj.cadastroEmUso = true;
      return obj;
    } else {
      obj.codigoCadastro = this.contribuinteFormatado.cadastro.codigo;
      obj.codigoContribuinte = this.contribuinteFormatado.codigo;
      obj.dscIe = this.contribuinteFormatado.dscIe;
      obj.dscNomeFantasia = this.contribuinteFormatado.dscNomeFantasia;
      obj.dscRazaoSocial = this.contribuinteFormatado.dscRazaoSocial;
      obj.seqProcuracao = this.contribuinteFormatado.seqProcuracao;
      obj.cpfCnpj = this.contribuinteFormatado.cadastro.cpfCnpj;
      return obj;
    }
  }

  validarContribuinteEmUso() {
      if (!this.contribuinteEmUso) {
        return false
      } else {
        return true
      }
  }
}