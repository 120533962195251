<section class="margem-top container">
  <h3>Caixa Postal Eletrônica</h3>
  <div class="br-table">
    <div class="table-header">
      <!-- <div>
        <button type="button" class="btn btn-primary" (click)="exibirModalTipoComunicado()">Iniciar Comunicação</button>
      </div> -->
    <div class="filtros">
      <div class="br-input col-4">
        <input
          id="protocoloFiltro"
          type="number"
          name="protocolo"
          placeholder="Pesquisar comunicação por Protocolo"
          [(ngModel)]="protocolo"
          onkeydown="return event.keyCode !== 69"
          (blur)="getFiltroProtocolo($event)"
        />
          <button class="br-button" type="button" aria-label="Buscar" [disabled]="!protocolo" (click)="filtrarComunicadoEletronico()">
            <fa-icon [icon]="['fas', 'search']" aria-hidden="true"></fa-icon>
          </button>
      </div>
    </div>
    <div class="filtros">
      <div class="filtros__botoes">
          <span class="filtros__label">Filtros: </span>
        <div ngbDropdown>
          <button class="btn btn-primary" ngbDropdownToggle>Período</button>
            <div ngbDropdownMenu>
              <div class="titulo">
                <span class="titulo__string">Período</span>
              </div>
              <div class="margin-dropdown dropdown-tamanho__datepicker datepicker-flex">
                  <dp-day-calendar
                    theme="dp-material"
                    [(ngModel)]="dataInicio"
                    (onSelect)="getFiltroDataInicio($event)"
                    [displayDate]="estadoDataInicial"
                    #daypicker
                  ></dp-day-calendar>
                  <dp-day-calendar
                    theme="dp-material"
                    [(ngModel)]="dataFim"
                    (onSelect)="getFiltroDataFim($event)"
                    [displayDate]="estadoDataInicial"
                    (minDate)="dataInicio"
                    #daypicker
                  ></dp-day-calendar>
              </div>
            </div>
        </div>
        <div ngbDropdown>
          <button class="btn btn-primary" ngbDropdownToggle>Tipo de Comunicação</button>
          <div class="filtroEscuro dropdownTipoComunicado" ngbDropdownMenu>
            <div *ngFor="let item of listaTiposComunicacao; let i = index" class="margin-dropdown dropdownTipoComunicado_item">
              <div class="br-radio">
                <input 
                  [id]="item.codigo" 
                  type="radio" 
                  name="tipoComunicacao"
                  [value]="item.codigo"
                  (click)="getFiltroTipoComunicacao(item.codigo)"
                  [(ngModel)]="radioComunicacao"
                /> 
                <label
                  [for]="item.codigo"
                  >{{ item.descricao }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <div ngbDropdown>
          <button class="btn btn-primary" ngbDropdownToggle>Validade</button>
          <div class="filtroEscuro" ngbDropdownMenu>
            <div class="margin-dropdown dropdown-tamanho">
              <div class="br-radio">
                <input
                  id="cienciaSemPrazo"
                  type="radio"
                  name="validade"
                  value="comCiencia"
                  (click)="getFiltroValidade($event)"
                  [(ngModel)]="radioValidade"
                />
                <label
                for="cienciaSemPrazo"
                  >Com ciência
                </label>
              </div>
              <div class="br-radio">
                <input
                  id="prazoVencer"
                  type="radio"
                  name="validade"
                  value="prazoCienciaVencer"
                  (click)="getFiltroValidade($event)"
                  [(ngModel)]="radioValidade"
                />
                <label
                for="prazoVencer"
                  >Prazo da ciência a vencer
                </label>
              </div>
              <div class="br-radio">
                <input
                  id="prazoRespostaVencer"
                  type="radio"
                  name="validade"
                  value="prazoRespostaVencer"
                  (click)="getFiltroValidade($event)"
                  [(ngModel)]="radioValidade"
                />
                <label
                for="prazoRespostaVencer"
                  >Prazo para a reposta vencer
                </label>
              </div>
              <div class="br-radio">
                <input
                  id="prazoVencidoResposta"
                  type="radio"
                  name="validade"
                  value="prazoRespostaVencido"
                  (click)="getFiltroValidade($event)"
                  [(ngModel)]="radioValidade"
                  />
                  <label
                  for="prazoVencidoResposta"
                    >Prazo vencido para reposta
                  </label>
              </div>
            </div>
          </div>
        </div>
        <div ngbDropdown>
          <button class="btn btn-primary" ngbDropdownToggle>Situação</button>
          <div class="filtroEscuro" ngbDropdownMenu>
            <div class="margin-dropdown dropdown-tamanho">
              <div class="br-radio">
                <input
                  id="lido"
                  type="radio"
                  name="situacao"
                  value="lido"
                  (click)="getFiltroSituacao($event)"
                  [(ngModel)]="radioSituacao"
                />
                <label for="lido">Comunicados Lidos</label>
              </div>
              <div class="br-radio">
                <input
                  id="respondido"
                  type="radio"
                  name="situacao"
                  value="respondido"
                  (click)="getFiltroSituacao($event)"
                  [(ngModel)]="radioSituacao"
                />
                <label for="respondido">Comunicados Respondidos</label>
              </div>
              <div class="br-radio">
                <input
                  id="naoRespondido"
                  type="radio"
                  name="situacao"
                  value="naoRespondido"
                  (click)="getFiltroSituacao($event)"
                  [(ngModel)]="radioSituacao"
                />
                <label for="naoRespondido">Comunicados Não Respondidos</label>
              </div>
            </div>
          </div>
        </div>
        <button type="button" class="btn btn-primary btn-filtro" (click)="filtrarComunicadoEletronico()">Aplicar</button>
        <button type="button" class="btn btn-secondary btn-filtro" (click)="limparFiltro()" *ngIf="filtroAtivo">Limpar Filtro</button>
      </div>
    </div>
      <div class="filtros">
        <button type="button" class="btn btn-primary" title="Checar Novas Comunicações " (click)="atualizar()" >
          <img class="imagem-comunicados"
            src="./assets/images/icones/arrow-rotate-right-solid.svg" alt="Botão de Checar Novas Comunicações" title="Checar Novas Comunicações">
            Checar Novas Comunicações
        </button>
      </div>
      <div class="top-bar">
        <div class="table-title mt-3 mb-3">Comunicações</div>
        <div class="mr-2 filtroPesquisa">
          <span class="mr-1">Exibir</span>
            <select class="form-control form-control-sm" id="tamanhoPagina" name="tamanhoPagina" (change)="alterarTamanhoPagina($event)">
              <option value="10" [selected]="true">
                  10
              </option>
              <option value="20">
                  20
              </option>
              <option value="50">
                  50
              </option>
              <option value="100">
                  100
              </option>
            </select>
          <span class="ml-1">comunicações</span>
        </div>
      </div>
    </div>
    <div class="table-container">
      <table class="table">
        <thead>
          <tr>
            <th class="col-small" scope="col">Protocolo</th>
            <th class="col-medium" scope="col">Tipo de Comunicação</th>
            <th class="col-medium" scope="col">Modelo de Comunicação</th>
            <th class="col-large" scope="col">Assunto</th>
            <th class="col-small" scope="col">Data de Recebimento</th>
            <th class="col-small" scope="col">Data de Leitura</th>
            <th class="col-medium" scope="col">Tipo de Ciência</th>
            <th class="col-small" scope="col">Data de Ciência</th>
            <th class="col-small" scope="col">Prazo p/ Resposta</th>
            <th class="col-small" scope="col">Data da Resposta</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngIf="comunicados.length; else semDados">
          <tr *ngFor="let item of this.comunicados" (click)="redirect(item.seqCaixaPostal)" 
            [ngClass]="{
              'bold': !item.teveCienciaExpressa && (item.ultimaResposta === false || !item.naoLido), 
              'vermelho': item.corLetra == 'vermelho', 
              'verde': item.corLetra == 'verde', 
              'amarelo': item.corLetra == 'amarelo'
            }"
          >
            <td><img class="carimbo" src="../../../assets/images/icones/stamp.svg" alt="Carimbo">{{ item.seqCaixaPostal }}</td>
            <td>{{ item.tipoComunicado.tipoComunicacao.descricao }}</td>
            <td 
              [ngStyle]="{'background': item.tipoComunicado.corComunicado.descricaoHtml, 'color': '#fff'}"
              id="tipoComunicado"
              class="tipoComunicado"
              >
              {{ item.tipoComunicado.dscTipoComunicado }}</td>
            <td>{{ item.dscAssunto }}</td>
            <td>{{ item.datCaixaPostal | date: 'dd/MM/yyyy' }}</td>
            <td>{{ getDataLeitura(item.situacaoCaixaPostal) || 'Não Ocorreu' }}</td>
            <td>{{ item.ciencias ? (item.ciencias[0]?.tipoCiencia?.dscTipoCiencia || 'Sem ciência') : "" }}</td>
            <td>{{ item.ciencias && item.ciencias.length > 0 ? (item.ciencias[0]?.datCiencia | date:'dd/MM/yyyy') : 'Não Ocorreu' }}</td>
            <td>{{ gerarPrazoResposta(item) }}</td>
            <td>{{ item.datResposta ? (item.datResposta | date: 'dd/MM/yyyy') : 'Sem resposta' }}</td>
          </tr>
          </ng-container>
          <ng-template #semDados>
            <tr>
              <td colspan="100%" align="center">Sem dados para serem exibidos.</td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
  </div>
  <div *ngIf="quantidadeElementosPagina != 0">
    <p class="mt-5">Total: {{totalElementos}} | Exibindo: {{quantidadeElementosPagina}} | Página Atual: {{pagina}}</p>
  </div>
  <div class="container-paginacao">
    <button class="br-button secondary mt-5 button-first" (click)="primeiraPagina()" [disabled]="pagina == 1 || pagina == 0" [ngClass]="{ disabled: pagina == 1, 'botao-proibido': pagina == 1}">Primeira</button>
    <button class="br-button secondary mt-5 button-first" (click)="paginaAnterior()" [disabled]="pagina == 1 || pagina == 0" [ngClass]="{ disabled: pagina == 1, 'botao-proibido': pagina == 1}">Anterior</button>
    <p class="mt-5">{{ pagina }} de {{ quantidadePaginas }}</p>
    <button class="br-button secondary mt-5  button-first" (click)="proximaPagina()" [disabled]="pagina == quantidadePaginas || quantidadePaginas == 0" [ngClass]="{ disabled: pagina == quantidadePaginas, 'botao-proibido': pagina == quantidadePaginas }">Próxima</button>
    <button class="br-button secondary mt-5 button-first" (click)="ultimaPagina()" [disabled]="pagina == quantidadePaginas || quantidadePaginas == 0" [ngClass]="{ disabled: pagina == quantidadePaginas, 'botao-proibido': pagina == quantidadePaginas }">Última</button>
  </div>
  <div class="div-botao">
    <button class="br-button secondary mt-5 posicao-botao" type="button" [routerLink]="['/inicio']">Retornar</button>
  </div>
</section>
<div class="carregamento" *ngIf="loading">
  <img src="../../../assets/images/icones/logo-animado.svg">
</div>

<app-definir-tipo-comunicado 
  *ngIf="exibirTipoComunicado"
  (proximo)="exibirModalEnviar($event)"
  (fecharModal)="exibirModalTipoComunicado()"
></app-definir-tipo-comunicado>

<app-enviar-comunicado
  *ngIf="exibirEnviarComunicado"
  [tipoComunicado]="tipoComunicadoSelecionado"
  (fecharModal)="fecharEnviarComunicado()"
></app-enviar-comunicado>