<header class="br-header" *ngIf="!logged">
  <div class="container-lg">
    <div class="header-top">

      <div class="header-logo">
        <img class = "header-image-logo-left" src="../assets/images/imagens/logo_sfera.png" alt="Logo SFERA"/>
        <img class = "header-image-logo" src="../assets/images/imagens/logos.png" alt="Logo SEFAZ"/>
        <span class="br-divider-logo vertical"></span>
      </div>
      <div class="header-actions">
        <div class="header-links dropdown">
          <button class="br-button circle small" type="button" data-toggle="dropdown" aria-label="Abrir Acesso Rápido"><i class="fas fa-ellipsis-v" aria-hidden="true"></i>
          </button>
        </div><span class="br-divider vertical mx-half mx-sm-1"></span>
        <div class="header-functions dropdown">
          <div class="br-list">
            <div class="br-item">
              <button class="circle small" type="button" aria-label="Modo Escuro"><fa-icon [icon]="['fas', 'adjust']" aria-hidden="true" (click)="ativarModoEscuro()"></fa-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="header-login">
          <div class="header-sign-in" *ngIf="mostrarBotao">
            <button class="br-sign-in primary large" type="button" (click)="entrar()"><fa-icon [icon]="['fas', 'user']" aria-hidden="true"></fa-icon><span class="d-sm-inline bold">Entrar</span>
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</header>

<header class="br-header logged-shadow" *ngIf="logged">
  <div class="container-lg">
    <div class="header-top">
      <div class="header-logo">
        <img class = "header-image-logo-left" src="../assets/images/imagens/logo_sfera.png" alt="Logo SFERA"/>
        <img class = "header-image-logo" src="../assets/images/imagens/logos.png" alt="Logo SEFAZ"/>
        <span class="br-divider-logo vertical"></span>
      </div>
      <div class="header-actions">
        <div class="header-links dropdown">
          <button class="br-button circle small" type="button" data-toggle="dropdown" aria-label="Abrir Acesso Rápido"><i class="fas fa-ellipsis-v" aria-hidden="true"></i>
          </button>
        </div><span class="br-divider vertical mx-half mx-sm-1"></span>
        <div class="header-functions dropdown">
          <button class="br-button circle small" type="button" data-toggle="dropdown" aria-label="Abrir Funcionalidades do Sistema"><i class="fas fa-th" aria-hidden="true"></i>
          </button>
          <div class="br-list">
            <div class="br-item">
              <button class="header-modoEscuro br-button circle small" type="button" aria-label="Modo Escuro"><fa-icon [icon]="['fas', 'adjust']" aria-hidden="true" (click)="ativarModoEscuro()"></fa-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="header-login">
          <div class="header-sign-in" ngbDropdown>
            <button class="br-button large tamanho" type="button" id="dropdownBasic1" ngbDropdownToggle>
              <img src="../../../../assets/images/imagens/user.svg" class="logged-img">
              <span class="profile-text">Olá</span>
              <span class="d-sm-inline bold profile-text nomeUsuario">, {{ cpfCnpjUsuario | mask: 'CPF_CNPJ' }} - {{ nomeUsuario }}</span>
              <img src="../../../assets/images/imagens/seta_baixo.svg" class="seta">
            </button>
            <ul class="modoEscuro" ngbDropdownMenu aria-labelledby="dropdownBasic1">
              <li>
                <p class="dropdown-margin">Olá, <span class="d-sm-inline bold profile-text">{{ nomeUsuario }}</span></p>
              </li>
              <li>
                <button class="modoEscuroTxt dropdown-item btn-user" routerLink="/meu-perfil">
                  <span class="profile-text"><fa-icon [icon]="['fas', 'user']" aria-hidden="true"></fa-icon></span>
                  Meu Perfil
                </button>
              </li>
              <li class="trocarRepre">
                <button class="modoEscuroTxt dropdown-item btn-user d-flex align-items-center" routerLink="/fale-conosco">
                  <img class="ml-1" [src]="modoEscuro ? '../../../assets/images/icones/white-circle-exclamation-solid.svg' : '../../../assets/images/icones/circle-exclamation-solid.svg'" width="16px" class="me-2">
                  Fale Conosco
                </button>
              </li> 
              <li>
                <button class="modoEscuroTxt dropdown-item btn-user" (click)="abrirAjuda()">
                  <span class="profile-text">
                    <fa-icon [icon]="['fas', 'circle-question']" aria-hidden="true"></fa-icon>
                  </span>
                  Ouvidoria
                </button>
              </li>
              <li class="trocarRepre">
                <button class="modoEscuroTxt dropdown-item btn-user d-flex align-items-center" routerLink="/selecao-representante">
                  <img [src]="modoEscuro ? '../../../assets/images/icones/white-shuffle-solid.svg' : '../../../assets/images/icones/shuffle-solid.svg'" width="18px" class="me-2">
                  Trocar Representante
                </button>
              </li>            
              <li>
                <a href="https://sso.acesso.gov.br/logout"><button class="br-button secondary xsmall dropdown-button mt-2" (click)="logout()">Sair</button></a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="header-bottom">
      <div class="header-menu">
        <div class="header-info">
          <div *ngIf="perfilAcesso" class="header-title">{{ perfilAcesso }}: DEC - <span class="header-title">{{ cpfCnpjContribuinte | mask: 'CPF_CNPJ' }} - {{ nomeContribuinte }} </span></div>
        </div>
      </div>
    </div>
  </div>
</header>
