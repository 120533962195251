<section class="margem-top container">
  <h5>Comunicação Eletrônica</h5>
  <div *ngFor="let comunicado of comunicados; let index = index">
    <div class="br-table">
      <div class="table-header dataDscAssunto">
        <div class="top-bar">
          <div class="table-title">{{ comunicado.dscAssunto }}</div>
        </div>
        <div>
          <p class="dataDscAssunto_dataHora">{{ comunicado.datCaixaPostal | date: 'dd/MM/yyyy HH:mm'}} </p>
        </div>
      </div>
      <div class="responder">
        <div>
          <p class="destinatarioRemetente">De: {{ comunicado.remetenteComunicado.nome }}</p>
          <p class="destinatarioRemetente">Para: {{ comunicado.numOrigem == 1 ? comunicados[0].remetenteComunicado.nome : comunicado.destinatarioComunicado.nome }}</p>
        </div>
        <div class="responder__resposta-comunicado">
          <div class="responder__numero-comunicado">
            <img class="carimbo" src="../../../assets/images/icones/stamp.svg" alt="Carimbo"><p>{{ comunicado.seqCaixaPostal }}</p>
          </div>
          <div class="top-bar">
            <button (click)="permitirRespostaToggle()" class="responder__botao" *ngIf="!responder && index == 0">Responder</button>
            <button (click)="abrirNomearResponsavel()" class="responder__botao" *ngIf="comunicado.tipoComunicado.numNomeado && index == 0">Nomear Responsável</button>
            <button class="responder__botao btnHistorico" (click)="visualizarHistorico()"  *ngIf="index == 0">
              Cópia de Inteiro Teor
              <span>
                <img class="iconeDownload" src="../../../../assets/images/icones/download.svg" alt="Ícone de download">
              </span>
            </button>
          </div>
        </div>
      </div>
      <div class="top-bar"  [ngClass]="{'divisoria': !comunicado.temAnexo}">
        <app-comunicado [htmlComunicado]="comunicado.txtMensagem"></app-comunicado>
      </div>
      <div *ngIf="responder && index == 0">
        <div class="top-bar">
          <div class="br-textarea">
            <textarea class="textarea" rows="5" cols="88" [(ngModel)]="respostaComunicado" placeholder="Digite aqui sua resposta..."></textarea>
            <p class="mensagem-alerta" *ngIf="respostaComunicado.length < 10 && respostaComunicado.length > 0">Obrigatório no mínimo 10 dígitos no corpo da mensagem</p>
          </div>
        </div>
        <div>
          <div class="top-bar">
            <label for="file-input" class="responder__botao-anexo">Anexo
              <img class="responder__icone" src="../../../../assets/images/icones/paper-clip.svg" alt="Ícone de inserir anexo.">
            </label>
            <input id="file-input" type="file" accept="application/pdf" multiple="multiple" (change)="anexo($event)" #fileUpload>
            <div class="anexos__enviar-margem">
              <button class="botao-modoEscuro anexos__fechar" (click)=" fechar()">Fechar</button>
              <button (click)="responderComunicado()" class="botao-modoEscuro anexos__enviar" [disabled]="respostaComunicado.length < 10">Enviar</button>
            </div>
          </div>
          <div class="ml-4" *ngIf="files.length == 0">
            Ainda não há arquivos.
          </div>
          <div class="file-upload ml-4">
            <p *ngFor="let item of files; first as isFirst; let i = index">{{ item.name }}
              <fa-icon class="lixeira" [icon]="['fas', 'trash']" (click)="deletarArquivo(i)"></fa-icon>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="anexos divisoria" *ngIf="comunicado.temAnexo">
      <p class="anexos__titulo">
        Anexos ({{ comunicado.anexos.length }})
      </p>
      <div class="anexos__container-botao">
        <button *ngFor="let anexo of comunicado.anexos"
          class="anexos__botao anexos__botao-display"
          [title]="anexo.dscNomeAnexo"
          (click)="downloadAnexo(anexo)"
        >
        <span class="anexos__nomeAnexo">{{ anexo.dscNomeAnexo }}</span>
        <span>
          <img class="anexos__icone" src="../../../../assets/images/icones/download.svg" alt="Ícone de download">
        </span>
        </button>
      </div>
      <div>
    </div>
  </div>
</div>
<div class="div-botao">
  <button class="br-button secondary mt-5 mr-4 anexos__botao-retorno" type="button" routerLink="/caixa-postal">Retornar</button>
</div>
</section>

<div class="backdrop" *ngIf="modalNomearResponsavel">
  <div class="div br-modal x-large position-modal">
    <div class="row">
      <div class="ml-5 br-modal-header col-10">Nomear responsável por atender a demanda do comunicado em destaque</div>
      <button class="ml-5 close-button col-1" (click)="fecharNomearResponsavel()" aria-label="Fechar">        
        &times;
      </button>
    </div>

    <div class="br-modal-body overflow">
      <div class="mt-3 mb-4">
        <button [disabled]="botaoDesabilitado" class="botao-modoEscuro br-button primary" (click)="exibirFormNomear()" >Adicionar Responsável</button>
        <button [disabled]="botaoDesabilitado" class="botao-modoEscuro br-button danger ml-4" (click)="fecharNomearResponsavel()">Desistir de Nomear</button>
      </div>

      <div class="br-table col-12 mt-4">
        <table>
          <thead>
            <tr>
              <th scope="col">C.P.F</th>
              <th scope="col">Nome</th>
              <th scope="col">Data Nomeação</th>
              <th scope="col">Data Remoção</th>
              <th scope="col">Ações</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngIf="listaNomeacoes.length > 0; else semDados"></ng-container>
            <tr *ngFor="let item of listaNomeacoes" class="cursor">
              <td>{{ item.dscCpfCnpj | mask: 'CPF_CNPJ' }}</td>
              <td>{{ item.nomeCadastro }}</td>
              <td>{{ item.dataNomeacao | date: 'dd/MM/yyyy' }}</td>
              <td>{{ item.dataRemocao ? (item.dataRemocao | date: 'dd/MM/yyyy') : '-' }}</td>
              <td>
                <button class="br-button" (click)="removerNomeacao(item)" title="Remover a nomeação" [disabled]="item.dataRemocao !== null">
                  <img
                  class="iconeRemoverNomeacao"
                  [src]="modoEscuro ? '../../../assets/images/icones/white-thumbs-down-regular.svg' : '../../../assets/images/icones/thumbs-down-regular.svg'"
                  alt="Remover a nomeação">
                </button>
              </td>
            </tr>
            <ng-template #semDados>
              <tr>
                <td colspan="7" align="center">Sem dados para serem exibidos.</td>
              </tr>
            </ng-template>
          </tbody>
        </table>
      </div>
    </div>
    <div class="br-modal-footer justify-content-center">
    </div>
  </div>
</div>

<div class="backdrop" *ngIf="exibirCamposNomear">
  <div class="div br-modal x-large position-modal">
    <div class="row">
      <div class="ml-5 br-modal-header col-10">Nomear responsável por atender a demanda do comunicado em destaque</div>
      <button class="ml-5 close-button col-1" (click)="fecharFormNomear()" aria-label="Fechar">        
        &times;
      </button>
    </div>
    <div class="br-modal-body overflow">
      <div *ngIf="exibirCamposNomear" class="row mt-4">
        <div class="br-input col-4">
          <label id="cpf">C.P.F</label>
          <input
            type="text"
            name="cpf"
            id="cpf"
            [(ngModel)]="cpfNomear"
            placeholder="C.P.F"
            maxlength="14"
            mask="CPF_CNPJ"
            (blur)="validarCpf()"
          />
          <button class="br-button" type="button" (click)="validarCpf()">
            <fa-icon [icon]="['fas', 'search']"></fa-icon>
          </button>
        </div>
        <div class="br-input col-8">
          <label id="nome">Nome</label>
          <input
            type="text"
            name="nome"
            id="nome"
            placeholder="Nome"
            [(ngModel)]="nomeNomear"
            disabled
          />
        </div>

        <div class="mt-4 botoesNomear">
          <button
            class="botao-modoEscuro br-button primary ml-4 mr-4"
            (click)="nomearResponsavel()"
            [disabled]="this.cpfNomear == '' || this.cpfNomear.length < 11 || this.nomeNomear == ''"
          >Nomear</button>
          <button class="botao-modoEscuro br-button danger" (click)="fecharFormNomear()">Desistir</button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="backdrop" *ngIf="modalHistorico">
  <div class="div br-modal x-large position-modal-historico">
    <div class="br-modal-header">Visualizar Cópia de Inteiro Teor</div>
    <div class="br-modal-body overflow">
      <div class="d-flex justify-content-center mb-2">
        <button class="botao-modoEscuro br-button primary" (click)="baixarHistorico()">Baixar Cópia de Inteiro Teor</button>
        <button class="botao-modoEscuro br-button danger ml-4" (click)="modalHistorico=false">Fechar</button>
      </div>
      <pdf-viewer
        [src]="src"
        [rotation]="0"
        [original-size]="false"
        [show-all]="true"
        [fit-to-page]="false"
        [zoom]="0.8"
        [zoom-scale]="'page-width'"
        [stick-to-page]="false"
        [render-text]="true"
        [external-link-target]="'blank'"
        [autoresize]="true"
        [show-borders]="true"
        style="width: 100%; height: 600px"
        (after-load-complete)="pdfCarregado()"
      >
      </pdf-viewer>
    </div>
  </div>
</div>


<div class="carregamento" *ngIf="loading">
  <img src="../../../assets/images/icones/logo-animado.svg">
</div>
