import { Component, Input, ViewEncapsulation } from "@angular/core";

@Component({
  selector: 'app-comunicado',
  templateUrl: './comunicado.component.html',
  styleUrls: ['./comunicado.component.scss'],
  encapsulation: ViewEncapsulation.ShadowDom
})
export class ComunicadoComponent {

  @Input() htmlComunicado: string;

  constructor() {

  }


}