import { Component, OnInit, ViewChild } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { InicioComponent } from "../../inicio/inicio.component";
import { nfaService } from "src/app/services/nfa.service";
import { MenuService } from "src/app/services/menu.service";
import { Observable, timer } from "rxjs";
import { mapTo } from "rxjs/operators";
import { UsuarioLogadoService } from "src/app/services/usuarioLogado.service";

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss']
})

export class SidemenuComponent implements OnInit{
  @ViewChild('inicioComponent') inicioComponent: InicioComponent;
  
  sidemenuStorage: any;
  sidemenu: any = 'false';
  logged: boolean = false;
  nivelUsuario: string;
  loading: boolean = false;
  contribuinteEmUso$: Observable<any>;
  contribuinteEmUso: string;
  contribuinteFormatado: any;
  cadastroEmUso: any;
  objTermoAdesao: any;
  termoAdesao: any;
  registroTermoAdesao: boolean = false;
  backdrop: boolean = false;
  modalAtivo: boolean = false;
  dadosUsuario: any;
  objUsuario: any;
  codigoContribuinte: string = '';
  dadosFormatados$: Observable<any>;
  dadosFormatados: any;
  dadosIntegracao: any;
  perfil: string = '';
  menusAutorizados: any[] = []
  menus = [
    { dscMenu: 'Caixa Postal Eletrônica' },
    { dscMenu: 'Procuração Eletrônica' },
    { dscMenu: 'REFIS' },
    { dscMenu: 'Portal de Negociação de Débitos' }, 
    { dscMenu: 'NFA-e' },
    { dscMenu: 'Portal de Acompanhamento de Parcelamento / Acordos' },
    { dscMenu: 'Cadastro de Contribuintes do I.C.M.S' },
    { dscMenu: 'IPVA' },
    { dscMenu: 'Perfil do Contribuinte' }
  ];

  constructor(
    private router: Router,
    private abrirFecharNfa: nfaService,
    private menuService: MenuService,
    private usuarioLogadoService: UsuarioLogadoService
  ) {
    this.router.events.subscribe((ev) => {
      if(ev instanceof NavigationEnd) {
        if(ev.url == '/inicio') {
          this.logged = true;
        }

        if(ev.url == '/selecao-representante') {
          this.logged = false;
        }
      }
    })
  }

  ngOnInit(): void {  

    this.dadosFormatados$ = this.usuarioLogadoService.getDadosIntegracaoSubject();
    this.dadosFormatados$.subscribe(
      res =>  {
        this.dadosFormatados = res;
      }
    )
    this.contribuinteEmUso$ = this.usuarioLogadoService.getContribuinteEmUsoSubject();
    this.contribuinteEmUso$.subscribe(
      res => {
        this.contribuinteFormatado = res;
        console.log(this.contribuinteFormatado);
        
      }
    )
    setTimeout(() => {
      this.nivelUsuario = localStorage.getItem('nivelUsuario');
      this.contribuinteEmUso = localStorage.getItem('contribuinteEmUso');
      this.dadosIntegracao = localStorage.getItem('dadosIntegracao');
      this.cadastroEmUso = localStorage.getItem('cadastroEmUso'); 
      
      this.getMenusAutorizados(); 
    }, 1000);
  }

  getMenusAutorizados() {
    const stringPerfilSelecionado = localStorage.getItem('perfilSelecionado');
    let perfilAcesso;
    if(stringPerfilSelecionado !== 'undefined') { 
      perfilAcesso = JSON.parse(stringPerfilSelecionado);
    }

    if (perfilAcesso?.codigo == 2) {
      this.menuService.getMenusAutorizados(perfilAcesso?.codigo).subscribe((res: any[]) => {
        const menusPerfil = res;
        this.menusAutorizados = this.menus.filter(menu => {
          return menusPerfil.some(menuPerfil => menuPerfil.dscMenu.toUpperCase() == menu.dscMenu.toUpperCase());
        });
      });
    } else if(perfilAcesso?.codigo == 3) {
      this.menuService.getMenusAutorizados(perfilAcesso?.codigo, this.contribuinteFormatado.seqProcuracao).subscribe((res: any[]) => {
        const menusPerfil = res;
        this.menusAutorizados = this.menus.filter(menu => {
          return menusPerfil.some(menuPerfil => menuPerfil.dscMenu.toUpperCase() == menu.dscMenu.toUpperCase());
        });
      });
    }  
  }

  abrirSidemenu() {
    this.getMenusAutorizados();
    localStorage.setItem('sidemenu', 'true');
    this.sidemenu = localStorage.getItem('sidemenu');
  }

  fecharSidemenu() {
    this.sidemenu = 'false';
    localStorage.setItem('sidemenu', 'false');
  }

  logout() {
    localStorage.removeItem('sidemenu');
    localStorage.removeItem('dadosIntegracao');
    localStorage.removeItem('contribuinteEmUso');
    localStorage.removeItem('cadastroEmUso');
    localStorage.removeItem('token');
    localStorage.removeItem('primeiroAcesso');
    localStorage.removeItem('perfilSelecionado');
    this.sidemenu = 'false';
    window.location.href = "https://sso.acesso.gov.br/logout";
    window.location.href = "https://dfe.sefaz.to.gov.br";
    // this.logoutService.logout().subscribe();
  }
  
  abrirModal(){
    if (this.modalAtivo == true) {
      this.modalAtivo = false;
    } else {
      this.modalAtivo = true;
    }
  }

  abrirNegociacaoDebito(){
    this.dadosUsuario = localStorage.getItem('contribuinteEmUso');
    this.objUsuario = JSON.parse(this.dadosUsuario);
    this.codigoContribuinte = this.objUsuario.cadastro.cpfCnpj;
    const codigoContribuinteMock = 'credor=60&tp=7&d=' + this.codigoContribuinte;
    const base64 = btoa(codigoContribuinteMock);
    const externalUrl = 'https://portaldidat.sefaz.to.gov.br/Start.aspx?dec=' + base64;
    window.open(externalUrl, '_blank');
    this.modalAtivo = false;
  }

  abrirPortalAcompanhamento() {
    this.dadosUsuario = localStorage.getItem('contribuinteEmUso');
    this.objUsuario = JSON.parse(this.dadosUsuario);
    this.codigoContribuinte = this.objUsuario.cadastro.cpfCnpj;
    const codigoContribuinte = 'credor=60&tp=3&d=' + this.codigoContribuinte;
    const base64 = btoa(codigoContribuinte);
    const externalUrl = ' https://portaldidat.sefaz.to.gov.br/Start.aspx?dec=' + base64;
    window.open(externalUrl, '_blank');
  }

  abrirRefisIpva(){
    const externalUrl = 'https://ipva.sefaz.to.gov.br/como_pagar_ipva.php';
    window.open(externalUrl, '_blank');
  }

  abrirModalNfa() {
    this.abrirFecharNfa.abrirFecharNfa();
    
  }

  // exibirMenu(menu): boolean {
  //   let exibeMenu: boolean = true;
  //   if(this.menusAutorizados.length !== 0 && this.menusAutorizados !== undefined) {
  //     exibeMenu = this.menusAutorizados.some(menuPerfil => menuPerfil.dscMenu.toUpperCase().includes(menu.toUpperCase()));
  //   }
    
  //   return exibeMenu
  // }

  exibirMenu(menu: string): boolean {
    
    if (!this.menusAutorizados.length) {
      setTimeout(() => {
        this.getMenusAutorizados();
      }, 500); 
      return false; 
    }
  
    return this.menusAutorizados.some(menuPerfil => 
      menuPerfil.dscMenu.toUpperCase() === menu.toUpperCase()
    );
  }
}
