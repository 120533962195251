<div class="top container">
  <ul class="crumb-list" *ngIf="breadcrumbs.length > 0">
    <li class="crumb home"><a class="br-button circle" (click)="redirecionarHome()"><span class="sr-only">Página inicial</span><fa-icon [icon]="['fas', 'home']"></fa-icon></a></li>
    <li class="crumb" *ngFor="let breadcrumb of breadcrumbs">
      <fa-icon [icon]="['fas', 'chevron-right']"></fa-icon>
      <a class="pointer" [routerLink]="breadcrumb.url">
        {{ breadcrumb.label }}
      </a>
    </li>
  </ul>
</div>