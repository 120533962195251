import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { ContribuinteService } from "src/app/services/contribuinte.service";
import { MenuService } from "src/app/services/menu.service";
import { ModoEscuroService } from "src/app/services/modoEscuro.service";
import { TokenService } from "src/app/services/token.service";
import Swal from "sweetalert2";

@Component({
  selector: 'app-selecao-representante',
  templateUrl: './selecao-representante.component.html',
  styleUrls: ['./selecao-representante.component.scss']
})
export class SelecaoRepresentanteComponent implements OnInit{

  perfil: string = '';
  listaProcuradores: any = [];
  listaContribuintes: any = [];
  listaSocios: any = [];
  listaPaginada: any;
  dadosIntegracao: any;
  dadosFormatados: any;
  objUsuario: any;
  tipoPessoa: number;
  pagina: number = 1;
  quantidadePaginas: number;
  quantidadePorPagina: number = 10;
  retornarDesativado: boolean = false;
  modoEscuro: boolean = false;
  perfisAcesso: any[] = [];

  constructor(
    private datePipe: DatePipe,
    private router: Router,
    private modoEscuroService: ModoEscuroService,
    private contribuinteService: ContribuinteService,
    private menuService: MenuService,
    private tokenService: TokenService
  ) {

  }

  ngOnInit(): void {
    this.dadosIntegracao = localStorage.getItem('dadosIntegracao');
    this.dadosFormatados = JSON.parse(this.dadosIntegracao);
    this.tipoPessoa = this.dadosFormatados.sessao.cadastro.tipoPessoa;
    if (this.dadosFormatados.sessao.procurador) {
      this.listaProcuradores = this.dadosFormatados.sessao.procurador;
    } else {
      this.listaProcuradores = null;
    }

    if (this.dadosFormatados.sessao.contribuinte) {
      this.listaContribuintes = this.dadosFormatados.sessao.contribuinte;
    } else {
      this.listaContribuintes.push(this.dadosFormatados.sessao.cadastro);
    }

    if (this.dadosFormatados.sessao.socio) {
      this.listaSocios = this.dadosFormatados.sessao.socio;
    } else {
      this.listaSocios = null;
    }
    this.organizarContribuintesEmpresa();
    this.retirarContribuintesRepetidos();
    this.validarPerfil();
    this.getPerfisAcesso();

    this.modoEscuroService.modoEscuro$.subscribe(modo => {
      this.modoEscuro = modo;
    });

    
  }

  getPerfisAcesso() {
    this.menuService.getPerfisAcesso().subscribe((res: any[]) => {
      this.perfisAcesso = res;
    });
  }

  organizarContribuintesEmpresa() {
    if (this.dadosFormatados.sessao.empresasCadastro) {
      for (let i = 0; i < this.dadosFormatados.sessao.empresasCadastro.length; i++) {
        if (this.dadosFormatados.sessao.empresasCadastro[i].contribuintes.length == 0) {
          this.listaContribuintes = this.listaContribuintes.concat(this.dadosFormatados.sessao.empresasCadastro[i]);
        }
      }
      let arrayContribuintes = [];
      for (let i = 0; i < this.dadosFormatados.sessao.empresasCadastro.length; i++) {
        if (this.dadosFormatados.sessao.empresasCadastro[i].contribuintes) {
          arrayContribuintes = arrayContribuintes.concat(this.dadosFormatados.sessao.empresasCadastro[i].contribuintes);
        }
      }
      this.listaContribuintes = this.listaContribuintes.concat(arrayContribuintes);
    }
  }

  retirarContribuintesRepetidos() {
    const codigos = this.listaContribuintes.map(( { codigo }) => codigo);
    const filtrado = this.listaContribuintes.filter(( { codigo }, index) => !codigos.includes(codigo, index + 1));
    this.listaContribuintes = filtrado;
  }

  procuradorSelecionado(objProcurador) {
    this.objUsuario = objProcurador.contribuinte;
    this.objUsuario.seqProcuracao = objProcurador.id;
    if (this.verificarContribuinteLogado()) {
      let params = {
        usuario: objProcurador.contribuinte.cadastro.codigo,
        procurador: objProcurador.id
      }
      this.resetarToken(params);
    }
    localStorage.setItem('contribuinteEmUso', JSON.stringify(this.objUsuario));
    this.setPerfilAcesso('PROCURADOR');
    this.router.navigate(['/inicio']);
  }

  contribuinteSelecionado(objContribuinte) {
    this.objUsuario = objContribuinte;
    if (this.verificarContribuinteLogado()) {
      let params = {
        usuario: objContribuinte.contribuinte.cadastro.codigo
      }
      this.resetarToken(params);
    }
    this.setPerfilAcesso('CONTRIBUINTE');
    if ((objContribuinte.contribuintes && objContribuinte.contribuintes.length > 0) || objContribuinte.cadastro ) {
      localStorage.setItem('contribuinteEmUso', JSON.stringify(this.objUsuario));
      this.router.navigate(['/inicio']);
      return
    } else {
      this.contribuinteService.criarCadastroContribuinte(objContribuinte.codigo).subscribe(
        res => {
          localStorage.setItem('contribuinteEmUso', JSON.stringify(res));
        },
        err => {
          Swal.fire({
            icon: 'error',
            text: 'Ops! Ocorreu uma falha de comunicação. Tente novamente mais tarde.'
          })
        },
        () => {
          this.router.navigate(['/inicio']);
        }
      )
    }
  }

  socioSelecionado(objSocio) {
    this.objUsuario = objSocio.contribuinte;
    localStorage.setItem('contribuinteEmUso', JSON.stringify(this.objUsuario));
    this.setPerfilAcesso('SÓCIO');
    this.router.navigate(['/inicio']);
  }

  escolherPerfil(perfil: string) {
    if (perfil == 'contribuinte') {
      this.perfil = 'contribuinte';
      this.setPerfilAcesso('CONTRIBUINTE');

      if (this.listaContribuintes.length == 1 && this.dadosFormatados.sessao.contribuinte) {
        localStorage.setItem('contribuinteEmUso', JSON.stringify(this.listaContribuintes[0]));
        this.router.navigate(['/inicio']);
        return
      } else if (this.listaContribuintes.length == 1 && !this.dadosFormatados.sessao.contribuinte) {
        localStorage.setItem('cadastroEmUso', JSON.stringify(this.listaContribuintes[0]));
        this.router.navigate(['/inicio']);
      }
    } else if (perfil == 'procurador') {
      if (this.listaProcuradores?.length == 1 && !this.listaSocios) {
        let seqProcuracao = this.listaProcuradores[0].id;
        this.listaProcuradores[0].contribuinte.seqProcuracao = seqProcuracao;
        localStorage.setItem('contribuinteEmUso', JSON.stringify(this.listaProcuradores[0].contribuinte));
        this.setPerfilAcesso('PROCURADOR');
        this.router.navigate(['/inicio']);
        return
      } else {
        if (this.listaSocios?.length == 1 && !this.listaProcuradores) {
          localStorage.setItem('contribuinteEmUso', JSON.stringify(this.listaSocios[0].contribuinte));
          this.setPerfilAcesso('SÓCIO');
          this.router.navigate(['/inicio']);
          return
        }
      }
      this.perfil = 'procurador';
    } else if (perfil == 'socio') {
      this.perfil = 'socio';
    }
    this.organizarPaginacao();
  }

  validarPerfil() {
    if (this.listaContribuintes && (this.listaProcuradores || this.listaSocios)) {
      return
    } else if (this.listaContribuintes && !this.listaProcuradores && !this.listaSocios) {
      if (this.listaContribuintes.length == 1) {
        localStorage.setItem('contribuinteEmUso', JSON.stringify(this.listaContribuintes[0]));
        setTimeout(() => {
          this.setPerfilAcesso('CONTRIBUINTE');
        }, 500);
        this.router.navigate(['/inicio']);
        return
      }
      this.escolherPerfil('contribuinte');
      this.retornarDesativado = true;
    } else if (!this.dadosFormatados.sessao.contribuinte && !this.listaProcuradores && this.listaSocios) {
      return
    } else if (!this.dadosFormatados.sessao.contribuinte && this.listaProcuradores && !this.listaSocios) {
      return
    } else {
      this.router.navigate(['/inicio']);
    }
  }

  setPerfilAcesso(perfil) {
    const perfilSelecionado = this.perfisAcesso.find(res => res.descricao.toUpperCase().includes(perfil));
    localStorage.setItem('perfilSelecionado', JSON.stringify(perfilSelecionado));
  }

  organizarPaginacao() {
    this.listaPaginada = [];
    if (this.perfil == 'contribuinte') {
      this.quantidadePaginas = this.listaContribuintes.length / 10;
      this.quantidadePaginas = Math.trunc(this.quantidadePaginas);
      if (this.listaContribuintes.length % 10 > 0) {
        this.quantidadePaginas += 1;
      }
      this.listaPaginada = [];
      this.listaPaginada = this.listaContribuintes.slice((this.pagina - 1) * 10, this.quantidadePorPagina * this.pagina);
    } else if (this.perfil == 'procurador') {
      this.quantidadePaginas = this.listaProcuradores.length / 10;
      this.quantidadePaginas = Math.trunc(this.quantidadePaginas);
      if (this.listaProcuradores.length % 10 > 0) {
        this.quantidadePaginas += 1;
      }
      this.listaPaginada = [];
      this.listaPaginada = this.listaProcuradores.slice((this.pagina - 1) * 10, this.quantidadePorPagina * this.pagina);
    } else if (this.perfil == 'socio') {
      this.quantidadePaginas = this.listaSocios.length / 10;
      this.quantidadePaginas = Math.trunc(this.quantidadePaginas);
      if (this.listaSocios.length % 10 > 0) {
        this.quantidadePaginas += 1;
      }
      this.listaPaginada = [];
      this.listaPaginada = this.listaSocios.slice((this.pagina - 1) * 10, this.quantidadePorPagina * this.pagina);
      
    }
  }

  verificarContribuinteLogado() {
    let contribuinteExiste: boolean;
    localStorage.getItem('contribuinteEmUso') ? contribuinteExiste = true : contribuinteExiste = false;
    return contribuinteExiste;
  }

  resetarToken(params) {
    this.tokenService.resetToken(params).subscribe(
      res => {
        this.tokenService.setToken(res["jwtToken"]);
      }
    )
  }

  primeiraPagina() {
    this.pagina = 1;
    this.organizarPaginacao();
    this.scroll();
  }

  proximaPagina() {
    this.pagina += 1;
    this.organizarPaginacao();
    this.scroll();
  }

  paginaAnterior() {
    this.pagina -= 1;
    this.organizarPaginacao();
    this.scroll();
  }

  ultimaPagina() {
    this.pagina = this.quantidadePaginas;
    this.organizarPaginacao();
    this.scroll();
  }

  voltarSelecao() {
    this.pagina = 1;
    this.listaPaginada = [];
    this.perfil = '';
    this.scroll();
  }

  scroll() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}